@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


body {
  background-color: #0C0A1F;
  font-family: "Montserrat", sans-serif, "Montserrat";
}

.ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Cards {
  p {
    font-size: 16px;
    font-weight: 600;
  }
}

.ant-select .ant-select-arrow {
  color: white;
}

.ant-menu-item {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.ant-slider {
  margin: 0px 3px;
}
.ant-table-wrapper .ant-table-thead > tr > th {
  border-bottom: none;
}
.ant-table-container table > thead > tr:first-child > *:last-child {
  border-bottom-right-radius: 12px;
}

.ant-table-container table > thead > tr:first-child > *:first-child {
  border-bottom-left-radius: 12px;
}
.ant-table-thead {
  @media only screen and (max-width: 598px) {
    //display: none;
  }
}
/////////////////////////TABLE ROWS//////////////////////

.Tabel-Row-status-div {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 7px 8px;
  max-width: 90px;
  cursor: pointer;
}

.Tabel-Row-hash-div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.Tabel-Row-icon-div {
  display: flex;
  align-items: center;
  gap: 5px;

  img {
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }
}

.mobile-row-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Tabel-Row-status-div-mobile {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 2px;
  width: 120px;
}

.expandable-data-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.ant-spin-dot-item {
  background-color: #33FFFF !important;
}

.ant-btn-primary:disabled {
  color: #000 !important;
  background-color: #33FFFF !important;
  border-color: #33FFFF !important;
  opacity: 1 !important;
}

.ant-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}

.Customize-Text.ant-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  text-align: left;
}

.mytickets-tabel-status {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 8px;
  border-radius: 8px;
  max-width: 80px;
  background-color: rgba(255, 255, 255, 0.05);
}

.ticket-detail-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:last-child {
  border-bottom-right-radius: 0px;
}

.ticket-detail-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  border-bottom-left-radius: 0px;
}

.ticket-detail-table .ant-table-container table > thead > tr > * {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

td.ant-table-cell-row-hover {
  background-color: rgba(255, 255, 255, 0.02) !important;
  opacity: 1;
}
@media only screen and (max-width: 960px) and (min-width: 600px) {
.cardoverview{
width: 50%!important;
}
}

.username-field::placeholder{
  text-align: left;
  font-family: "Montserrat";
  font-size: 16px;
}
.username-field{
  background:rgba(255, 255, 255, 0.150);
  font-family: "Montserrat";
  padding: 5px 10px;
  font-size: 16px;
}

button.overview-offer-btn:hover{
  background-color: #33FFFF!important;
  color: #000!important;
}

button.ant-btn-primary:disabled{
  opacity: 0.4!important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #120f2d; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffffff28; 
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffffff7a; 
}